import styled from "@emotion/styled";
import { MIN_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
export const StyledWithBackgroundBannerComponent = styled.div`
  @media (max-width: ${MIN_HEADER_DESKTOP_MENU}px) {
    .banner {
      height: unset;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`;
